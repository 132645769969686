import React from "react";
import { SidebarLayout } from "../../components/Layout";
import SettingsSidebar from "../../components/SettingsSidebar";

const SettingsPage = () => {
  return (
    <SidebarLayout seo={{ title: "Settings" }} sidebar={<SettingsSidebar />}>
      Edit your settings here.
    </SidebarLayout>
  );
};

export default SettingsPage;
