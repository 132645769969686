import {
  AtSymbolIcon,
  ClipboardListIcon,
  CogIcon,
  IdentificationIcon,
  KeyIcon,
  LockClosedIcon,
  QrcodeIcon,
  ShieldCheckIcon,
  ShieldExclamationIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";
import { Link } from "gatsby";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addMessage } from "../state/reducers/appReducer";
import { setSettingsState } from "../state/reducers/settingsReducer";
import { RootState } from "../state/store";
import { iconProps } from "../styles/settings";
import { fetchBaseOptions } from "../utils/fetch";

const Section = (props: {
  title: string;
  icon: typeof IdentificationIcon;
  items: {
    title: string;
    to: string;
    icon: typeof IdentificationIcon;
    enabled?: boolean;
  }[];
}) => {
  const items = props.items.map(i => (
    <Link
      key={i.to}
      className="flex items-center p-2 space-x-2 text-black hover:text-black hover:no-underline hover:bg-neutral-50"
      activeClassName="bg-neutral-100"
      to={i.to}
    >
      <i.icon {...iconProps} />
      <span>{i.title}</span>
      {i.enabled !== undefined && (
        <span
          className={classNames(
            "px-2 py-1 text-xs border rounded-3xl",
            i.enabled
              ? "bg-green-50 text-green-700 border-green-700"
              : "bg-neutral-100 text-neutral-700 border-neutral-700",
          )}
        >
          {i.enabled ? "Enabled" : "Disabled"}
        </span>
      )}
    </Link>
  ));
  return (
    <section className="pb-6">
      <h3 className="flex items-center p-2 space-x-2 text-lg border-b">
        <props.icon {...iconProps} />
        <span>{props.title}</span>
      </h3>
      <div>{items}</div>
    </section>
  );
};

interface IAPISettingsResponse {
  initialized: boolean;
  email: string;
  totpEnabled: boolean;
  recoveryCodesGenerated: boolean;
}

export default () => {
  const dispatch = useDispatch();

  const settings = useSelector((state: RootState) => state.settings);
  const has2FASecurityKeyEnabled = false; //TODO
  const has2FAEnabled = settings.totpEnabled || has2FASecurityKeyEnabled;

  useEffect(() => {
    if (!settings.initialized) {
      fetch("/api/settings", {
        ...fetchBaseOptions,
        method: "GET",
      })
        .then(async res => {
          if (res.ok) {
            const j: IAPISettingsResponse = await res.json();
            dispatch(setSettingsState(j));
          } else {
            const j: IAPIResponse = await res.json();
            dispatch(addMessage(j));
          }
        })
        .catch(err => console.error(err));
    }
  }, []);

  return (
    <nav>
      <h2 className="flex items-center px-2 mb-4 space-x-2 text-xl font-semibold">
        <CogIcon {...iconProps} />
        <span>Settings</span>
      </h2>
      <Section
        title="Account"
        icon={IdentificationIcon}
        items={[
          {
            title: "Update email",
            to: "/settings/account/email/update",
            icon: AtSymbolIcon,
          },
          {
            title: "Change password",
            to: "/settings/account/password/change",
            icon: LockClosedIcon,
          },
          {
            title: "Delete account",
            to: "/settings/account/delete",
            icon: TrashIcon,
          },
        ]}
      />
      <Section
        title="Two factor authentication"
        icon={has2FAEnabled ? ShieldCheckIcon : ShieldExclamationIcon}
        items={[
          {
            title: "Authenticator app",
            to: "/settings/security/totp",
            icon: QrcodeIcon,
            enabled: settings.totpEnabled,
          },
          {
            title: "Security key",
            to: "/settings/security/key",
            icon: KeyIcon,
            enabled: has2FASecurityKeyEnabled,
          },
          {
            title: "Recovery codes",
            to: "/settings/security/recovery",
            icon: ClipboardListIcon,
            enabled: settings.recoveryCodesGenerated,
          },
        ]}
      />
    </nav>
  );
};
